<template>
  <perfect-scrollbar
    :options="{ suppressScrollX: true }"
    class="scroll"
    style="max-height: 75vh; position: relative"
  >
    <v-card flat tile class="border-light-grey">
      <v-card-title class="headline grey lighten-4">
        <v-layout>
          <v-flex class="my-auto">
            <h3 class="custom-sub-header-blue-text my-0">Profile</h3>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateProfile()"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-progress-linear
        indeterminate
        height="6"
        v-if="pageLoading"
        class="position-absolute"
        color="blue darken-4"
      ></v-progress-linear>
      <v-card-text class="p-6">
        <v-row>
          <v-col md="8">
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="company-name" class="btx-label mt-2">Company Name</label>
              </v-flex>
              <v-flex md8>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="company-name"
                  placeholder="Company Name"
                  v-model="profile.company_name"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="phone-number" class="btx-label mt-2">Phone Number</label>
              </v-flex>
              <v-flex md8>
                <!-- <PhoneTemplate
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="phone-number"
                  placeholder="Phone Number"
                  v-model="profile.phone_number"
                ></PhoneTemplate> -->
                <PhoneInput
                  required
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="customer-phone-no"
                  placeholder="Phone No."
                  v-model="profile.phone_number"
                  :validation-field="{
                    url_type: 'customer',
                    filter_type: 'contact',
                    field: 'phone_number',
                  }"
                ></PhoneInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="whatsapp-number" class="btx-label mt-2">Whatsapp Number</label>
              </v-flex>
              <v-flex md8>
                <!-- <PhoneTemplate
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="whatsapp-number"
                  placeholder="Whatsapp Number"
                  v-model="profile.whatsapp_number"
                ></PhoneTemplate> -->
                <PhoneInput
                  required
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="customer-phone-no"
                  placeholder="Phone No."
                  v-model="profile.whatsapp_number"
                  :validation-field="{
                    url_type: 'customer',
                    filter_type: 'contact',
                    field: 'phone_number',
                  }"
                ></PhoneInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="fax-number" class="btx-label mt-2">Fax Number</label>
              </v-flex>
              <v-flex md8>
                <!-- <PhoneTemplate
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="fax-number"
                  placeholder="Fax Number"
                  v-model="profile.fax_number"
                ></PhoneTemplate> -->
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="fax-number"
                  placeholder="Fax Number"
                  v-model="profile.fax_number"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="website-url" class="btx-label mt-2">Website URL</label>
              </v-flex>
              <v-flex md8>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="website-url"
                  placeholder="Website URL"
                  v-model="profile.website_url"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="email-address" class="btx-label mt-2">Email</label>
              </v-flex>
              <v-flex md8>
                <EmailInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="email-address"
                  placeholder="Email"
                  v-model="profile.email_address"
                ></EmailInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="street-1" class="btx-label mt-2">Street 1</label>
              </v-flex>
              <v-flex md8>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="street-1"
                  placeholder="Street 1"
                  v-model="profile.street_1"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="street-2" class="btx-label mt-2">Street 2</label>
              </v-flex>
              <v-flex md8>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="street-2"
                  placeholder="Street 2"
                  v-model="profile.street_2"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="landmark" class="btx-label mt-2">Landmark</label>
              </v-flex>
              <v-flex md8>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="landmark"
                  placeholder="Landmark"
                  v-model="profile.landmark"
                ></TextInput>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md4 class="my-auto">
                <label for="zip-code" class="btx-label mt-2">Postal Code</label>
              </v-flex>
              <v-flex md8>
                <PostalCodeInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="zip-code"
                  placeholder="Postal Code"
                  v-model="profile.zip_code"
                ></PostalCodeInput>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat tile class="border-light-grey mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-layout>
          <v-flex class="my-auto">
            <h3 class="custom-sub-header-blue-text my-0">Reward Points</h3>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateProfile()"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="p-6">
        <v-row>
          <v-col md="6" class="py-0">
            <v-layout>
              <v-flex md6 class="my-auto">
                <label for="min-required" class="btx-label mt-2">Minimum Order Required</label>
              </v-flex>
              <v-flex md6>
                <v-checkbox
                  v-model="rewards.allow_min_amount"
                  hide-details
                  class="mt-0"
                  id="min-required"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="6" class="py-0 pb-2">
            <v-layout>
              <v-flex md6 class="my-auto">
                <label for="min-amount" class="btx-label mt-2">Minimum Order Amount</label>
              </v-flex>
              <v-flex md6>
                <CurrencyInput
                  hide-details
                  :disabled="pageLoading || !rewards.allow_min_amount"
                  :loading="pageLoading"
                  id="min-amount"
                  placeholder="Minimum Order Amount"
                  v-model="rewards.min_amount"
                ></CurrencyInput>
              </v-flex>
            </v-layout>
          </v-col>
          <v-col md="12" class="py-0 mt-4 text-center light-border-top">
            <label class="btx-label mt-2">Conversion Ratio for Points</label>
          </v-col>
          <v-col md="6">
            <label for="cr-amount" class="text-center light-border-top pt-2 btx-label mt-2"
              >Amount</label
            >
            <CurrencyInput
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="cr-amount"
              placeholder="Amount"
              v-model="rewards.cr_amount"
            ></CurrencyInput>
          </v-col>
          <v-col md="6">
            <label for="cr-point" class="text-center light-border-top pt-2 btx-label mt-2"
              >Point</label
            >
            <NumberInput
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              id="cr-point"
              placeholder="Point"
              v-model="rewards.cr_point"
            ></NumberInput>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat tile class="border-light-grey mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-layout>
          <v-flex class="my-auto">
            <h3 class="custom-sub-header-blue-text my-0">Social Networks</h3>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateProfile()"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="p-6">
        <v-row>
          <v-col md="6">
            <v-text-field
              v-model.trim="profile.facebook_url"
              placeholder="Facebook page URL"
              outlined
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="blue darken-4"
            >
              <template slot="prepend">
                <v-icon large class="facebook-prepend">mdi-facebook</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              v-model.trim="profile.twitter_url"
              placeholder="Twitter account URL"
              outlined
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="blue darken-4"
            >
              <template slot="prepend">
                <v-icon large class="twitter-prepend">mdi-twitter</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              v-model.trim="profile.instagram_url"
              placeholder="Instagram account URL"
              outlined
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="blue darken-4"
            >
              <template slot="prepend">
                <v-icon large class="instagram-prepend">mdi-instagram</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col md="6">
            <v-text-field
              v-model.trim="profile.google_plus_url"
              placeholder="Google business profile URL"
              outlined
              hide-details
              :disabled="pageLoading"
              :loading="pageLoading"
              color="blue darken-4"
            >
              <template slot="prepend">
                <v-icon large class="google-prepend">mdi-google-plus</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat tile class="border-light-grey mt-4">
      <v-card-title class="headline grey lighten-4">
        <v-layout>
          <v-flex class="my-auto">
            <h3 class="custom-sub-header-blue-text my-0">Business Hours</h3>
          </v-flex>
          <v-flex class="text-right">
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateProfile()"
            >
              Update
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="p-6">
        <table class="width-100">
          <tbody>
            <tr
              v-for="(day, index) in profile.business_hours"
              :key="index"
              :class="{
                'business-hours-enabled': !day.status,
              }"
            >
              <td width="200" class="my-auto">
                <label class="btx-label">{{ day.title }}</label>
              </td>
              <td width="200">
                <v-switch
                  class="mt-0"
                  v-model.trim="day.status"
                  inset
                  hide-details
                  color="cyan"
                ></v-switch>
              </td>
              <td class="px-4" :class="{ 'pointer-events-none': !day.status }">
                <TimePicker v-model="day.start_time" placeholder="Start Time"></TimePicker>
              </td>
              <td class="px-4" :class="{ 'pointer-events-none': !day.status }">
                <TimePicker v-model="day.end_time" placeholder="End Time"></TimePicker>
              </td>
            </tr>
          </tbody>
        </table>
      </v-card-text>
    </v-card>
  </perfect-scrollbar>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import EmailInput from "@/view/components/EmailInput";
import PostalCodeInput from "@/view/components/PostalCode";
import NumberInput from "@/view/components/NumberInput";
import CurrencyInput from "@/view/components/CurrencyInput";
// import PhoneTemplate from "@/view/components/Phone";
import PhoneInput from "@/view/components/PhoneInput";
import TimePicker from "@/view/components/TimePicker";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_PROFILE, UPDATE_PROFILE } from "@/core/lib/common.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { toSafeInteger } from "lodash";

export default {
  data() {
    return {
      pageLoading: false,
      rewards: {
        allow_min_amount: true,
        min_amount: 100,
        cr_amount: 10,
        cr_point: 1,
      },
      profile: {
        company_name: null,
        fax_number: null,
        phone_number: null,
        whatsapp_number: null,
        website_url: null,
        email_address: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        facebook_url: null,
        twitter_url: null,
        instagram_url: null,
        google_plus_url: null,
        business_hours: [],
      },
    };
  },
  components: {
    TextInput,
    NumberInput,
    EmailInput,
    PostalCodeInput,
    CurrencyInput,
    // PhoneTemplate,
    PhoneInput,
    TimePicker,
  },
  methods: {
    async getProfile() {
      const _this = this;
      try {
        _this.pageLoading = true;
        const result = await GET_PROFILE();
        _this.profile = {
          company_name: result.company_name,
          fax_number: result.fax_number,
          phone_number: result.phone_number,
          whatsapp_number: result.whatsapp_number,
          website_url: result.website_url,
          email_address: result.email_address,
          street_1: result.street_1,
          street_2: result.street_2,
          landmark: result.landmark,
          zip_code: result.zip_code,
          facebook_url: result.facebook_url,
          twitter_url: result.twitter_url,
          instagram_url: result.instagram_url,
          google_plus_url: result.google_plus_url,
          business_hours: result.business_hours,
        };
        _this.rewards = {
          allow_min_amount: !!+toSafeInteger(result.allow_min_amount),
          min_amount: result.min_amount,
          cr_amount: result.cr_amount,
          cr_point: result.cr_point,
        };
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async updateProfile() {
      const _this = this;
      try {
        _this.pageLoading = true;
        const form = {
          company_name: _this.profile.company_name,
          fax_number: _this.profile.fax_number,
          phone_number: _this.profile.phone_number,
          whatsapp_number: _this.profile.whatsapp_number,
          website_url: _this.profile.website_url,
          email_address: _this.profile.email_address,
          street_1: _this.profile.street_1,
          street_2: _this.profile.street_2,
          landmark: _this.profile.landmark,
          zip_code: _this.profile.zip_code,
          facebook_url: _this.profile.facebook_url,
          twitter_url: _this.profile.twitter_url,
          instagram_url: _this.profile.instagram_url,
          google_plus_url: _this.profile.google_plus_url,
          business_hours: _this.profile.business_hours,
          allow_min_amount: +!!_this.rewards.allow_min_amount,
          min_amount: _this.rewards.min_amount,
          cr_amount: _this.rewards.cr_amount,
          cr_point: _this.rewards.cr_point,
        };
        await UPDATE_PROFILE(form);
        this.getProfile();
        _this.$store.commit(SET_MESSAGE, [
          { model: true, message: "Setting Updated Successfully" },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.getProfile();
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Setting", disabled: true },
      { text: "Profile", disabled: true },
    ]);
  },
  beforeDestroy() {
    this.$store.dispatch(SET_BREADCRUMB, []);
  },
};
</script>
